.doniy-hight{
   height: max-content;
   display: grid;
   grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}
.doniy-hight li{
   padding: 1rem;
   list-style: none;
   background-color: #4c4c4c;
   text-align: center;
   color: white;
   border: 1px solid #5ccde1;
   cursor: pointer;
}
@media (max-width: 1200px){
   .doniy-hight{
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
   }
}
@media (max-width: 992px){
   .doniy-hight{
      grid-template-columns: 1fr 1fr 1fr 1fr ;
      font-size: 12px;
      
   }
}
@media (max-width: 670px){
   .doniy-hight{
      grid-template-columns: 1fr 1fr 1fr 1fr ;
      font-size: 10px;
      
   }
   .doniy-hight li{
      padding: 0.5rem;
      
   }
}
@media (max-width: 550px){
   .doniy-hight{
      grid-template-columns: 1fr 1fr 1fr ;
      font-size: 9px;
      
   }
   .doniy-hight li{
      padding: 0.25rem;
      
   }
}
@media screen and (min-width: 940px) {
    div.pushHeader {
        margin-top: 90px;
    }
}
@media screen and (max-width: 940px) {
    div.pushHeader {
        margin-top: 50px;
    }
}
@media screen and (min-width: 1100px) {
    div.pushHeader {
        margin-top: 100px;
    }
}



@media only screen and (min-width: 1000px) {
    div.logoPush {
        margin-left: -50px;
    }
}
.ui-select {
    width: 100%
}
/* This is to remove the arrow of select element in IE */
select::-ms-expand {
    display: none;
}
select {
    -webkit-appearance: none;
    appearance: none;
}
@-moz-document url-prefix() {
    .ui-select {
        border: 1px solid #CCC;
        border-radius: 4px;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;
    }
    .ui-select select {
        width: 110%;
        background-position: right 30px center !important;
        border: none !important;
    }
}


